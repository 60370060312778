<template>
	<div class="search" :style="isApp ? 'padding-top:1.913rem' : ''">
		<div class="search_top">
			<img src="~@/assets/images/new/new_010.png" alt="" class="zuo" @click="fLeft">
			<div class="search_box">
				<img src="~@/assets/images/new/new_001.png" alt="">
				<input type="text" id="search" placeholder="请输入要搜索的大神名称" v-model="oCPData.name" @input="fChangeInputVal">
			</div>
			<div class="search_title" @click="fGetDetailByName">搜索</div>
		</div>
		<template v-if="oCPData.name && oCPData.bSearch">
			<div v-if="oCPData.godNameData && oCPData.godNameData.roleId" class="search_jg"
				@click="toZhuye(oCPData.godNameData.roleId)">
				<img :src="oCPData.godNameData.head" alt="" v-if="oCPData.godNameData.head" class="tou5">
				<img src="~@/assets/images/new/new_019.png" alt="" v-else class="tou5">
				<div class="name4">{{oCPData.godNameData.roleName}}</div>
				<div class="bang_item_right1" style="margin-left: 0.267rem" v-if="oCPData.godNameData.evenRedCount >= 1">
					<div>
						<img src="~@/assets/images/new/new_005.png" alt="">
						<div class="font_box">
							<span
								style="font-size: 0.32rem;color: #E2335D;margin-right: 0.053rem">{{oCPData.godNameData.evenRedCount}}</span><span>连红</span>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="wsj_xob">
				<img src="~@/assets/images/img_021.png" alt="">
				<div>暂无数据</div>
			</div>
		</template>

		<template v-else>
			<div class="search_ts">
				<div>最近搜索</div>
				<img src="~@/assets/images/new/new_011.png" alt="" @click="fDelete">
			</div>
			<template v-if="oCPData.lsName && oCPData.lsName.length > 0">
				<div class="search_history">
					<div class="search_history_item" v-for="(item,index) in oCPData.lsName" :key="index" @click="dianFun(item)">
						{{item}}
					</div>
				</div>
			</template>
			<div v-else class="wsj_xob">
				<img src="~@/assets/images/img_021.png" alt="">
				<div>暂无数据</div>
			</div>
		</template>
	</div>
</template>

<script>
	import {
		apiGetDetailByName
	} from '@/api/home';
	import {
		Toast
	} from "vant";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		reactive,
		onMounted,
		// onUnmounted
	} from "vue";
	import {
		useRouter
	} from "vue-router";
	import {
		uaFun
	} from "@/utils/public.js";
	export default {
		name: "search",
		setup() {
			const isApp = uaFun()
			const router = useRouter(); //初始化路由
			// fLeft van-nav-bar 左侧点击事件


			const fLeft = () => {
				fnIsHasHistory("/");
				oCPData.name = undefined
				oCPData.godNameData = {}
			};
			const fGetDetailByName = () => {
				if (!oCPData.name) return oCPData.godNameData = {};
				Toast({
					type: "loading"
				});
				apiGetDetailByName({
					name: oCPData.name
				}).then(res => {
					oCPData.bSearch = true;
					oCPData.godNameData = res.result || {};
					let isChong = true
					if (oCPData.lsName.length > 0) {
						oCPData.lsName.forEach(e => {
							if (e == oCPData.name) {
								isChong = false
							}
						})
					}
					if (isChong) {
						if (oCPData.isStorage) {
							oCPData.lsName.push(oCPData.name)
							localStorage.setItem('lsName', JSON.stringify(oCPData.lsName))
						}
					}
				})
			};
			const fChangeInputVal = () => {
				if (!oCPData.name) {
					oCPData.godNameData = {};
					oCPData.bSearch = false;
				}
			};
			//删除历史记录
			const fDelete = () => {
				if (oCPData.isStorage) {
					localStorage.setItem('lsName', '')
					oCPData.lsName = []
				}
			};
			// 页面卸载
			onMounted(() => {
				// localStorage.clear()
				if (window.Storage && window.localStorage && window.localStorage instanceof Storage) {
					oCPData.isStorage = true
				} else {
					oCPData.isStorage = false
				}
				if (oCPData.isStorage) {
					oCPData.lsName = localStorage.getItem('lsName') ? JSON.parse(localStorage.getItem('lsName')) : []
					console.log(oCPData.lsName, '页面加载');
				}

			});
			const dianFun = (e) => {
				oCPData.name = e;
				fGetDetailByName()
			}
			const oCPData = reactive({
				name: '',
				bSearch: false,
				godNameData: {},
				lsName: [],
				isStorage: true
			});
			const toZhuye = (Id) => {
				// 跳转到方案详情
				router.push({
					path: '/zhuye',
					query: {
						id: Id
					}
				})
			}
			return {
				fLeft,
				oCPData,
				toZhuye,
				fGetDetailByName,
				dianFun,
				fDelete,
				isApp,
				fChangeInputVal
			}
		}
	}
</script>

<style scoped lang="scss">
	.search {
		padding-top: 0.613rem;
		box-sizing: border-box;

		.search_top {
			width: 100%;
			height: 0.907rem;
			display: flex;
			justify-content: space-between;
			padding: 0 0.4rem;
			box-sizing: border-box;

			align-items: center;

			.zuo {
				width: 0.213rem;
				height: 0.373rem;
			}

			.search_box {
				width: calc(100% - 2.667rem);
				height: 100%;
				border-radius: 0.453rem;
				border: 0.027rem solid #FF7671;
				display: flex;
				align-items: center;
				padding: 0 0.267rem;
				box-sizing: border-box;

				img {
					width: 0.4rem;
					height: 0.373rem;
					margin-right: 0.24rem;
				}

				input::-webkit-input-placeholder {
					color: #D2D2D2;
				}

				input::-moz-placeholder {
					/* Mozilla Firefox 19+ */
					color: #D2D2D2;
				}

				input:-moz-placeholder {
					/* Mozilla Firefox 4 to 18 */
					color: #D2D2D2;
				}

				input:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: #D2D2D2;
				}

				#search {
					height: 0.587rem;
					font-size: 0.427rem;
					font-weight: 400;
					color: #FF7671;
					line-height: 0.587rem;
					caret-color: #FF7671;
				}
			}

			.search_title {
				font-size: 0.427rem;
				font-weight: 400;
				color: #E2335D;
			}
		}

		.search_ts {
			width: 100%;
			height: 0.587rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 0.4rem;
			box-sizing: border-box;
			margin-bottom: 0.213rem;
			margin-top: 0.773rem;

			div {
				font-size: 0.427rem;
				font-weight: 400;
				color: #383838;
			}

			img {
				width: 0.533rem;
				height: 0.533rem;
			}
		}

		.search_history {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			padding: 0 0.4rem;
			box-sizing: border-box;

			.search_history_item {
				padding: 0 0.24rem;
				box-sizing: border-box;
				height: 0.533rem;
				background: #EAEAEA;
				border-radius: 0.267rem;
				font-size: 0.32rem;
				font-weight: 400;
				color: #979797;
				line-height: 0.533rem;
				text-align: center;
				margin-right: 0.32rem;
				margin-bottom: 0.213rem;
			}
		}

		.search_jg {
			.wsj_xob {
				width: 100%;
				/*height: 100%;*/
				padding-top: 2.667rem;
				box-sizing: border-box;

				img {
					width: calc(100% - 2.72rem);
					height: auto;
					margin: 0 1.36rem;
				}

				div {
					width: 100%;
					height: 0.453rem;
					font-size: 0.32rem;
					font-weight: 400;
					color: #979797;
					line-height: 0.453rem;
					text-align: center;
				}
			}

			width: calc(100% - 1.44rem);
			height: 1.493rem;
			margin: 0.453rem auto 0;
			display: flex;
			align-items: center;
			border-bottom: 0.013rem solid #F5F5F5;

			.tou5 {
				width: 1.013rem;
				height: 1.013rem;
				margin-right: 0.32rem;
				border-radius: 0.507rem;
			}

			.name4 {
				font-size: 0.373rem;
				font-weight: 400;
				color: #383838;
			}

			.bang_item_right1 {
				/*width: 1.067rem;*/
				height: 100%;
				padding-top: 0.32rem;
				box-sizing: border-box;
				margin-right: 0.267rem;

				div {
					height: 0.667rem;
					position: relative;

					img {
						width: 1.067rem;
						height: 0.667rem;
					}

					.font_box {
						width: 100%;
						height: 0.667rem;
						position: absolute;
						top: 0;
						right: 0;
						line-height: 0.973rem;
						text-align: center;

						span {
							font-size: 0.213rem;
							font-weight: 500;
							color: #383838;
						}
					}
				}

				/*height: 0.667rem;*/
			}
		}
	}

	.wsj_xob {
		width: 100%;
		/*height: 100%;*/
		padding-top: 2.667rem;
		box-sizing: border-box;

		img {
			width: calc(100% - 2.72rem);
			height: auto;
			margin: 0 1.36rem;
		}

		div {
			width: 100%;
			height: 0.453rem;
			font-size: 0.32rem;
			font-weight: 400;
			color: #979797;
			line-height: 0.453rem;
			text-align: center;
		}
	}
</style>