<template>
	<div class="zhuye app_flex">
		<div class="zhuye_tou"
			:style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;height: 5.273rem;background-size: 100% 5.273rem;' : ''">
			<div class="app_title_b">
				<van-sticky>
					<van-nav-bar :title="oCPData.title" left-arrow @click-left="fLeft" />
				</van-sticky>
			</div>
			<div class="user_box">
				<div class="bser_l">
					<img :src="oCPData.userData.head" alt="" v-if="oCPData.userData.head">
					<img src="~@/assets/images/new/new_019.png" alt="" v-else>
					<div class="user_title">
						<div class="title_top">
							{{oCPData.userData.roleName}}
						</div>
						<div class="title_bot">
							<!-- <div @click="fensi">粉丝 {{oCPData.userData.fansCount}}</div> -->
							<div @click="guanzhu">关注 {{oCPData.userData.focusCount}}</div>
							<!-- <div>点赞数 {{oCPData.userData.likeCount}}</div> -->
						</div>
					</div>
				</div>
				<div class="user_r" v-if="!oCPData.userData.focusState && oCPData.userData.roleId != oCPData.roleId"
					@click="focusOnFun">
					+ 关注
				</div>
				<div class="user_r user_r1"
					v-if="oCPData.userData.focusState && oCPData.userData.roleId != oCPData.roleId"
					@click="focusCancelFun">
					√ 关注
				</div>
				<!-- <div class="user_r user_r1" v-if="oCPData.userData.roleId == oCPData.roleId" @click="tobjzhuye">
					编辑个人信息
				</div> -->
			</div>
		</div>
		<div class="touzhu">
			<div class="zhutou_box">
				<div class="touzhu_item">
					<div class="item_top">{{oCPData.userData.weekBetCount}}</div>
					<div class="item_bot">近7天投注数</div>
				</div>
				<div class="shu"></div>
				<div class="touzhu_item">
					<div class="item_top">{{oCPData.userData.weekBetHit}}</div>
					<div class="item_bot">近7天命中</div>
				</div>
				<div class="shu"></div>
				<div class="touzhu_item">
					<div class="item_top">{{oCPData.userData.weekBetRecordBonus}}%</div>
					<div class="item_bot">近7天回报率</div>
				</div>
				<div class="shu"></div>
				<div class="touzhu_item">
					<div class="item_top">￥{{oCPData.userData.threeMonthsBonus}}</div>
					<div class="item_bot">近3个月总奖金</div>
				</div>
			</div>

		</div>
		<div class="zhuye_record">
			<div class="zhuye_record_div">
				<van-tabs v-model:active="active" swipeable title-active-color="#383838" title-inactive-color="#979797"
					@rendered="fOnRendered">
					<van-tab class="zhuye_record_div_tab" title="近7天投注记录" name="quarter">
						<div v-if="oPlanQuarter.list.length > 0" class="zhuye_rd_content">
							<van-list v-model:loading="oPlanQuarter.loading" v-model:error="oPlanQuarter.error"
								error-text="请求失败，点击重新加载" :finished="oPlanQuarter.finished" finished-text="没有更多了"
								@load="fOnLoadPlanAll" offset="100">
								<div class="plan_list" v-for="item in oPlanQuarter.list" :key="item"
									@click="toPlanDetailOther(item.id)">
									<div class="conter_top">
										<div class="conter_l">
											<div class="title">
												<div class="shu"
													:style="item.lotteryType == 'JCLQ' ? 'background: #F21B8F' : ''">
												</div>
												<div class="name" v-if="item.lotteryType == 'JCZQ'">竞彩足球</div>
												<div class="name" v-if="item.lotteryType == 'JCLQ'">竞彩篮球</div>
												<div class="bq" v-if="item.businessType == 1">发单</div>
												<!--                                <div class="bq" v-if="item.businessType == 2">自购</div>-->
												<div class="bq" v-if="item.businessType == 3"
													style="background: #F21B8F;color: #ffffff;">跟单</div>
											</div>
											<div class="qian">
												<div class="tou">
													<span>投注：</span><span class="qian1">￥{{item.totalFee}}</span>
												</div>
												<div class="wan">
													玩法：{{item.mixTypes}}
												</div>
											</div>
										</div>
										<div class="conter_r">
											<img src="~@/assets/images/img_019.png" alt=""
												v-if="item.weekBetRecordType == 2">
											<img src="~@/assets/images/img_017.png" alt=""
												v-if="item.weekBetRecordType == 3">
											<div v-if="item.weekBetRecordType == 3" class="jine">
												¥{{item.prizeFee}}
											</div>
											<div class="gemdam" style="margin-top: calc((100% - 0.533rem)/2)"
												v-if="item.weekBetRecordType == 1">立即跟单</div>
										</div>
									</div>
									<div class="conter_bot">
										<div class="sj">
											截止时间 {{fnFormatData(item.stopCopy, "MM-dd hh:mm")}}
										</div>
										<div class="rd">
											<span>
												热度
											</span>
											<span style="font-weight: bold">
												{{item.heat}}
											</span>
										</div>
									</div>
								</div>
							</van-list>
						</div>
						<div v-else class="app_scroll">
							<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
								description="暂无方案" />
						</div>
					</van-tab>
					<van-tab class="zhuye_record_div_tab" title="近半年中奖记录" name="halfYear">
						<div v-if="oPlanHalfYear.list.length > 0" class="zhuye_rd_content">
							<van-list v-model:loading="oPlanHalfYear.loading" v-model:error="oPlanHalfYear.error"
								error-text="请求失败，点击重新加载" :finished="oPlanHalfYear.finished" finished-text="没有更多了"
								@load="fOnLoadPlanUnpaid" offset="100">
								<div class="plan_list" v-for="item in oPlanHalfYear.list" :key="item"
									@click="toPlanDetailOther(item.id)">
									<div class="conter_top">
										<div class="conter_l">
											<div class="title">
												<div class="shu"
													:style="item.lotteryType == 'JCLQ' ? 'background: #F21B8F' : ''">
												</div>
												<div class="name" v-if="item.lotteryType == 'JCZQ'">竞彩足球</div>
												<div class="name" v-if="item.lotteryType == 'JCLQ'">竞彩篮球</div>
												<div class="bq" v-if="item.businessType == 1">发单</div>
												<!-- <div class="bq" v-if="item.businessType == 2">自购</div> -->
												<div class="bq" v-if="item.businessType == 3"
													style="background: #F21B8F;color: #ffffff;">跟单</div>
											</div>
											<div class="qian">
												<div class="tou">
													<span>投注：</span><span class="qian1">￥{{item.totalFee}}</span>
												</div>
											</div>
										</div>
										<div class="conter_r">
											<img src="~@/assets/images/img_017.png" alt="">
											<div class="jine">
												¥{{item.prizeFee}}
											</div>
										</div>
									</div>
									<div class="conter_bot">
										<div class="sj">
											{{fnFormatData(item.openPrize, "MM-dd hh:mm")}}
										</div>
									</div>
								</div>
							</van-list>
						</div>
						<div v-else class="app_scroll">
							<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')"
								description="暂无方案" />
						</div>
					</van-tab>
				</van-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		apiPlanGetHomeStatistic,
		apiPlanGetWeekBetRecord,
		apiPlanGetThreeMonthsBetRecord
	} from '@/api/plan';
	import {
		focusOn,
		focusCancel
	} from '@/api/mine';
	import {
		uaFun,
		fnFormatData
	} from "@/utils/public.js";
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter,
		onBeforeRouteLeave
	} from "vue-router";
	import {
		ref,
		inject,
		reactive,
		onActivated
	} from "vue";
	import {
		Toast
	} from 'vant';
	export default {
		name: "zhuye",
		setup() {
			const isApp = uaFun()
			const store = useStore();
			const route = useRoute();
			const fGlobalReload = inject("fGlobalReload");
			const UserInfo = store.state.userInfo.userInfo;
			const router = useRouter();

			const oCPData = reactive({
				userData: {},
				title: '个人主页',
				roleId: UserInfo.roleId
			})
			const active = ref("quarter");
			// 方案--一季度
			const oPlanQuarter = reactive({
				current: 0,
				size: 10,
				list: [],
				error: false,
				loading: false,
				finished: false
			});
			// 加载完成--方案--一季度
			const fOnLoadPlanAll = () => {
				const current = oPlanQuarter.current + 1;
				apiPlanGetWeekBetRecord({
					current: current,
					size: oPlanQuarter.size,
					roleId: route.query.id
				}).then(res => {
					oPlanQuarter.current = current;
					let tData = res && res.result ? res.result : {};
					let tRecords = tData.records ? tData.records : [];
					if (tRecords.length > 0) {
						oPlanQuarter.list.push(...tRecords);
						if (tData.hasNext) {
							oPlanQuarter.finished = false;
						} else {
							oPlanQuarter.finished = true;
						}
					} else {
						oPlanQuarter.finished = true;
					}
					oPlanQuarter.loading = false;
				}).catch(() => {
					oPlanQuarter.loading = false;
					oPlanQuarter.error = true;
				})
			};

			// 方案--半年
			const oPlanHalfYear = reactive({
				current: 0,
				size: 10,
				list: [],
				loading: false,
				finished: true
			});
			// 加载完成--方案--半年
			const fOnLoadPlanUnpaid = () => {
				const current = oPlanHalfYear.current + 1;
				apiPlanGetThreeMonthsBetRecord({
					current: current,
					size: oPlanHalfYear.size,
					roleId: route.query.id
				}).then(res => {
					oPlanHalfYear.current = current;
					let tData = res && res.result ? res.result : {};
					let tRecords = tData.records ? tData.records : [];
					if (tRecords.length > 0) {
						oPlanHalfYear.list.push(...tRecords);
						if (tData.hasNext) {
							oPlanHalfYear.finished = false;
						} else {
							oPlanHalfYear.finished = true;
						}
					} else {
						oPlanHalfYear.finished = true;
					}
					oPlanHalfYear.loading = false;
				}).catch(() => {
					oPlanHalfYear.loading = false;
					oPlanHalfYear.error = true;
				})
			};

			const fOnRendered = (Name) => {
				if (Name === "quarter") {
					oPlanQuarter.finished = false
				} else if (Name === "halfYear") {
					oPlanHalfYear.finished = false;
					fOnLoadPlanUnpaid()
				}
			}

			const queryMyFun = () => {
				apiPlanGetHomeStatistic({
					queryRoleId: route.query.id,
					roleId: UserInfo.roleId
				}).then(res => {
					oCPData.userData = res.result
				})
			}
			// 关注
			const focusOnFun = () => {
				focusOn({
					focusRoleId: route.query.id,
					roleId: UserInfo.roleId,
				}).then(res => {
					if (res.success) {
						Toast('关注成功');
						queryMyFun()
					}
				})
			}
			// 取消关注
			const focusCancelFun = () => {
				focusCancel({
					focusRoleId: route.query.id,
					roleId: UserInfo.roleId,
				}).then(res => {
					if (res.success) {
						Toast('取消关注');
						queryMyFun()
					}
				})
			}
			// 生命周期
			onActivated(() => {
				queryMyFun();
				fOnLoadPlanAll();
				if (route.query.id == UserInfo.roleId) {
					oCPData.title = '我的主页'
				} else {
					oCPData.title = '个人主页'
				}
			});
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/plan");
			};
			const toPlanDetailOther = (Id) => {
				// 跳转到方案详情
				router.push({
					path: '/planDetailOther',
					query: {
						id: Id
					}
				})
			}
			const tobjzhuye = () => {
				// 跳转到方案详情
				router.push({
					path: '/mineInfo'
				})
			}
			const fensi = () => {
				router.push({
					path: '/fensi',
					query: {
						id: oCPData.userData.roleId,
						userId: oCPData.userData.userId
					}
				})
			}
			const guanzhu = () => {
				router.push({
					path: '/guanzhu',
					query: {
						id: oCPData.userData.roleId,
						userId: oCPData.userData.userId
					}
				})
			}
			
			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				if (to.path === "/beijingball") {
					to.meta.resetType = "0";
					return true
				}
			})
			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				console.log(route_meta);
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fGlobalReload();
				} else {
					route_meta.resetType = '1'
				}
			});
			return {
				active,
				fLeft,
				fensi,
				guanzhu,
				fOnRendered,
				fnFormatData,
				tobjzhuye,
				toPlanDetailOther,
				focusOnFun,
				focusCancelFun,
				fOnLoadPlanAll,
				fOnLoadPlanUnpaid,
				oPlanQuarter,
				oPlanHalfYear,
				isApp,
				oCPData
			}
		}
	};
</script>

<style scoped lang="scss">
	.zhuye {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.zhuye_tou {
			width: 100%;
			height: 3.973rem;
			background-image: url('~@/assets/images/new/new_028.png');
			background-size: 100% 3.973rem;
			background-repeat: no-repeat;

			.user_box {
				width: calc(100% - 0.853rem);
				height: 1.813rem;
				margin-left: 0.427rem;
				display: flex;
				justify-content: space-between;
				margin-top: 0.667rem;

				.bser_l {
					height: 100%;
					display: flex;
					align-items: center;

					img {
						width: 1.813rem;
						height: 1.813rem;
						margin-right: 0.32rem;
						border-radius: 50%;
					}

					.user_title {
						height: 100%;
						padding: 0.32rem 0;
						box-sizing: border-box;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.title_top {
							font-size: 0.427rem;
							font-weight: 600;
							color: #383838;
						}

						.title_bot {
							display: flex;
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;

							div {
								margin-right: 0.32rem;
							}
						}
					}
				}

				.user_r {
					margin-top: 0.32rem;
					/*width: 1.733rem;*/
					padding: 0 0.187rem;
					box-sizing: border-box;
					height: 0.587rem;
					background: #FF7671;
					border-radius: 0.293rem;
					font-size: 0.32rem;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 0.587rem;
					text-align: center;
				}

				.user_r1 {
					border: 0.027rem solid #979797;
					color: #979797;
					background: rgba(0, 0, 0, 0);
				}
			}
		}

		.touzhu {
			width: 100%;
			background: linear-gradient(180deg, #FFFFFF 0%, #F1F2F7 100%);

			.zhutou_box {
				width: 100%;
				height: 1.573rem;
				display: flex;
				align-items: center;

				.touzhu_item {
					width: calc((100% / 4));
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.item_top {
						font-size: 0.4rem;
						font-weight: bold;
						color: #E2335D;
						margin-bottom: 0.133rem;
					}

					.item_bot {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
					}

				}

				.shu {
					width: 0.027rem;
					height: 0.56rem;
					background: #EAEAEA;
				}
			}
		}

		.zhuye_record {
			width: 100%;
			flex: 1;
			overflow: hidden;
			background: #FAFBFF;
			box-shadow: 0px 0.053rem 0.427rem 0px rgba(224, 228, 246, 0.5);
			border-radius: 0.32rem 0.32rem 0px 0px;

			.zhuye_record_div {
				height: 100%;
			}
			
			:deep(){
				.zhuye_record_div_tab .van-tab__panel {
					height: 100%;
				}
			}

			.zhuye_rd_content {
				height: 100%;
				overflow-x: auto;
			}
			

			.plan_list {
				width: calc(100% - 0.64rem);
				height: 3.627rem;
				margin: 0.32rem 0.32rem 0;
				background: #FFFFFF;
				box-shadow: 0px 0.053rem 0.213rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.213rem;
				padding: 0.213rem 0.4rem 0;
				box-sizing: border-box;

				.conter_top {
					display: flex;
					justify-content: space-between;
					padding-bottom: 0.427rem;
					box-sizing: border-box;
					border-bottom: 0.027rem solid rgba(234, 234, 234, 1);

					.conter_l {
						.title {
							display: flex;
							align-items: center;
							height: 0.48rem;
							margin-bottom: 0.507rem;

							.shu {
								width: 0.107rem;
								height: 0.48rem;
								background: #033EC2;
								border-radius: 0.053rem;
								margin-right: 0.107rem;
							}

							.name {
								font-size: 0.373rem;
								font-weight: 600;
								color: #383838;
								margin-right: 0.107rem;
							}

							.bq {
								width: 0.8rem;
								height: 0.48rem;
								background: #FED530;
								border-radius: 0.107rem;
								line-height: 0.55rem;
								text-align: center;
							}
						}

						.qian {
							margin-left: 0.107rem;

							.tou {
								font-size: 0.32rem;
								font-weight: 400;
								color: #383838;
								margin-bottom: 0.107rem;

								.qian1 {
									font-size: 0.427rem;
									font-weight: bold;
									color: #E2335D;
								}
							}

							.wan {
								font-size: 0.32rem;
								font-weight: 500;
								color: #383838;
							}
						}
					}

					.conter_r {
						img {
							width: 1.573rem;
							height: 1.52rem;
						}

						.jine {
							font-size: 0.427rem;
							font-weight: bold;
							color: #E2335D;
						}

						.gemdam {
							width: 1.92rem;
							height: 0.533rem;
							background: #FF7671;
							border-radius: 0.427rem;
							font-size: 0.32rem;
							font-weight: 500;
							color: #FFFFFF;
							line-height: 0.633rem;
							text-align: center;
						}
					}
				}

				.conter_bot {
					display: flex;
					justify-content: space-between;
					align-items: center;
					height: 0.853rem;

					.sj {
						font-size: 0.32rem;
						font-weight: 400;
						color: #979797;
					}

					.rd {
						font-size: 0.267rem;
						font-weight: 400;
						color: #E2335D;
					}
				}
			}
		}
	}
</style>
