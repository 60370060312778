import request from '@/utils/request';
import consts from '@/utils/consts';


// 轮播图获取
export function apiImgList(data) {
	return request({
		url: consts.businessPublic + '/home/getHomePicture',
		method: 'post',
		data: data
	})
}
// 中奖轮播公告
export function apiAnnouncementList(data) {
	return request({
		url: consts.businessPublic + '/home/getPrizeAnnouncement',
		method: 'post',
		data: data
	})
}

// 每日神单
export function apiGodOrderList(data) {
	return request({
		url: consts.businessPublic + '/home/getPerfectPlanOrder',
		method: 'post',
		data: data
	})
}

// 每日神单-新
export function apiNewGodOrderList(data) {
	return request({
		url: consts.businessPublic + '/home/getPerfectPlanOrderNew',
		method: 'post',
		data: data
	})
}

// 首页连红榜单
export function apiQueryAllEvenRedCount(data) {
	return request({
		url: consts.businessPublic + '/home/queryAllEvenRedCount',
		method: 'post',
		data: data
	})
}

// 搜索用户
export function apiGetDetailByName(data) {
	return request({
		url: consts.businessPublic + '/home/getDetailByName',
		method: 'post',
		data: data
	})
}
