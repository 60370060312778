import request from '@/utils/request'
import consts from '@/utils/consts'



// 是否有可以领取，且未领取的红包
export function apiHasGetable(data) {
	return request({
		url: consts.businessPublic + '/redpack/hasGetable',
		method: 'post',
		data: data
	})
}
// 大厅获取所有未过期，并且没被抢光的红包数量
export function apiRedpackNum(data) {
	return request({
		url: consts.businessPublic + '/redpack/countUsable',
		method: 'post',
		data: data
	})
}
// 获取方案详情里面的红包数量（包含方案已经结束的红包）
export function apiPlanRedpackNum(data) {
	return request({
		url: consts.businessPublic + '/redpack/countUsableByPlan',
		method: 'post',
		data: data
	})
}
// 根据方案Id获取红包列表
export function apiPlanRedpackList(data) {
	return request({
		url: consts.businessPublic + '/redpack/listCoverByPlan',
		method: 'post',
		data: data
	})
}

// 获取首页红包列表数据
export function apiRedpackList(data) {
	return request({
		url: consts.businessPublic + '/redpack/listIndexCover',
		method: 'post',
		data: data
	})
}

// 打开红包
export function apiRedpackOpen(data) {
	return request({
		url: consts.businessPublic + '/redpack/open',
		method: 'post',
		data: data
	})
}

// 抢红包
export function apiRedpackLoot(data) {
	return request({
		url: consts.businessPublic + '/redpack/loot',
		method: 'post',
		data: data
	})
}

// 获取红包明细
export function apiRedpackDetail(data) {
	return request({
		url: consts.businessPublic + '/redpack/getDetail',
		method: 'post',
		data: data
	})
}


// 获取我已经发出的红包数据
export function apiGiveOutList(data) {
	return request({
		url: consts.businessPublic + '/redpack/listSelfOwnRecord',
		method: 'post',
		data: data
	})
}

// 获取我已经领取红包的数据
export function apiReceiveList(data) {
	return request({
		url: consts.businessPublic + '/redpack/listSelfGainedRecord',
		method: 'post',
		data: data
	})
}




// 根据角色Id获取红包初始化信息
export function apiGetBaseInfo(data) {
	return request({
		url: consts.businessPublic + '/redpack/getBaseInfo',
		method: 'post',
		data: data
	})
}
// 发布红包
export function apiPublish(data) {
	return request({
		url: consts.businessPublic + '/redpack/publish',
		method: 'post',
		data: data
	})
}